.query-builder-container button {
  margin: 0px 5px !important;
  padding: 0px 5px !important;
}

.action--ADD-RULE {
  height: 35px !important;
}

.action--ADD-GROUP {
  height: 35px !important;
}

.action--DELETE {
  justify-content: center;
  display: flex;
  align-items: center;
}

.group--actions .group--actions--tr {
  justify-content: center;
  display: flex;
  align-items: center;
}

.group {
  /* background-color: var(--subheading); */
  /* border: 1px solid grey !important; */
  /* background-color: #e7eefc; */
  background-color: #f3f6fe;
  border: 1px solid var(--border-color);
  padding: 5px 0px;
}

.when-main-container {
  background-color: rgb(212, 207, 207);
  /* border: 1px solid grey !important; */
  border: 1px solid var(--border-color);
  padding: 5px 25px 5px 5px;
  border-radius: 8px;
}

.main-container {
  padding: 10px 20px;
  background-color: #f9f9f9;
  position: relative;
}

.main-container::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0%;
  width: 1px;
  background-color: #000;
  z-index: 10;
  transform: translateX(-50%);
}

.main-container::after {
  content: '';
  position: absolute;
  left: 0px;
  right: 0;
  height: 2px;
  background-color: grey;
  transform: translateY(-50%);
  width: 22px;
  top: calc(50%);
}

.half-height::before {
  bottom: 50%;
}

.multiple-option-query-builder .when-container {
  background-color: #cac9c9;
  padding: 10px 20px;
  margin: 10px 0px;
  border-radius: 8px;
  /* border: 1px solid grey !important; */
  border: 1px solid var(--border-color);
  position: relative;
}

.multiple-option-query-builder .multiple-option-main-container-parent .when-container {
  margin: 10px 5px;
}

.else-container {
  background-color: #cac9c9;
  padding: 5px 15px;
  margin: 5px 0px;
  border-radius: 8px;
  /* border: 1px solid grey !important; */
  border: 1px solid var(--border-color);
}

.when-container-then {
  background-color: #cac9c9;
  padding: 5px 15px;
  margin: 5px 10px;
  border-radius: 8px;
  /* border: 1px solid grey !important; */
  border: 1px solid var(--border-color);
}

.when-main-container>.when-container::before {
  content: '';
  position: absolute;
  top: 25px;
  bottom: 50%;
  left: 20px;
  width: 1px;
  background-color: grey;
  z-index: 10;
  transform: translateX(-50%);
}

.when-main-container>.when-container::after {
  content: '';
  position: absolute;
  left: 20px;
  right: 0;
  height: 2px;
  background-color: grey;
  transform: translateY(-50%);
  width: 18px;
  top: calc(50%);
}

.rule--func--bracket-before {
  height: 100%;
  align-items: center;
  display: flex;
}

.rule--func--bracket-after {
  height: 100%;
  align-items: center;
  display: flex;
}

.rule--body {
  display: flex;
  align-items: center;
}

.ant-radio-button-wrapper {
  background-color: aliceblue !important;
}

.rule--body--wrapper {
  height: fit-content;
}

.query-builder-container .query-builder{
 margin-left: 0;
 margin-right: 0;
 margin-top: 36px;
}

.query-builder-container .query-builder > .group-or-rule-container {
  padding-right: 0;
}

.query-builder-container .ant-btn-group >.ant-btn,
.query-builder-container .ant-btn-group >span>.ant-btn, 
.query-builder-container .ant-btn-group >.ant-btn>.ant-btn {
  border-radius: 24px;
}

.query-builder-container .ant-btn-group >.ant-btn,
.query-builder-container .ant-btn-group >span>.ant-btn, 
.query-builder-container .ant-btn-group >.ant-btn>.ant-btn {
  padding: 0 12px !important;
}

.query-builder-container .group--header .ant-btn-group {
  align-items: center;
}
/* .query-builder-container .group--header .ant-btn-group >.ant-btn.action--DELETE {
  margin-top: 4px !important;
} */

.query-btn-group .ant-btn{
  border-radius: 24px;
}

.single-option-query-btn-group.query-btn-group .ant-btn {
  width: 160px;
}

.mulitple-option-query-btn-group.query-btn-group .ant-btn {
  width: 202px;
}
.query-builder-container .anticon.anticon-ellipsis {
  cursor: pointer;
}

.query-builder-container .group--conjunctions .ant-btn {
  border: 1px solid #d9d9d9;
  height: 35px;
}

.query-builder-container .group--conjunctions .ant-btn.ant-btn-primary {
  border: 1px solid var(--primary-color);
  height: 35px;
}

.multiple-option-query-builder .query-builder-container .query-builder {
  margin: 1rem;
}