:root {
  --primary-color: #1434CB;
  --blue-color-400: #99b4f1;
  --blue-color-300: #cbdaf9;
  --blue-color-100: #e4ecfc;
  --border-gray-500: #d9d9d9;
  --black-color: #000000;
  --red-color: #ff4d4f;
  --label-color: #344054;
}



.dmn-form-generator-parent .ant-card-body {
  padding: 30px !important;
}

.form-title {
  font-size: 22px;
  font-weight: 600;
  margin: 10px 0px 15px 0px;
  color: #000000;
}

.label {
  font-size: 16px;
  font-weight: 600;
  color: var(--label-color);
  margin-bottom: 4px;
}

.delete-button {
  color: var(--red-color);
  border-color: var(--red-color);
}

.plus-icon {
  color: var(--primary-color);
  margin-right: 10px;
}

table.dmn-table>thead tr {
  background-color: var(--blue-color-400);
}

table.dmn-table>tbody tr:nth-child(even) {
  background-color: var(--blue-color-100);
}

td:last-child {
  width: 35px;
}

table.dmn-table>tbody tr input {
  border-radius: 6px;
  padding: 4px 8px;
  background-color: transparent;
  font-size: 14px;
}

table.dmn-table>tbody tr td {
  padding: 4px 8px;
}

table.dmn-table>tbody tr td {
  border-top: 0;
  border-bottom: 0;
}

th {
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-family: sans-serif;
}

/* :focus {
  outline: 1px solid gray;
} */
.row-delete-icon:focus,
.column-edit-icon:focus,
.select-menu:focus {
  outline: none;
}

input:focus-visible,
.row-delete-icon:focus-visible,
.column-edit-icon:focus-visible,
.select-menu:focus-visible {
  outline: 1px solid gray;
}

.flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.table-column {
  display: flex;
  width: 100%;
  align-items: center;
}

.table-column>input {
  flex: 1;
}

.column-edit-icon {
  margin-left: 4px;
  border-radius: 6px;
  cursor: pointer;
}

.row-delete-icon {
  border-radius: 6px;
  cursor: pointer;
}

.input-field-style {
  padding: 0px 11px;
  font-size: 16px;
  border: 1px solid var(--border-gray-500);
  background-color: white;
  border-radius: 6px;
  height: 42px;
  width: 200px;
  /* margin-bottom: 16px; */
}

.dmn-value-modal {
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  position: fixed;
  background: rgb(0 0 0 / 30%);
  overflow: hidden;
}

input[type="checkbox"] {
  width: 30px;
  height: 30px;
  accent-color: var(--primary-color);
  border: 0.25rem solid var(--border-gray-500);
  border-radius: 6px;
}

.checkbox-label {
  padding: 0px 10px;
}

.checkbox-container-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  border-radius: 6px;
  padding: 4px 11px;
}

.dmn-form-row {
  border-radius: 6px;
  align-items: end;
  gap: 15px;
  /* margin-bottom: 16px; */
}

.outlined-primary-btn {
  color: var(--primary-color);
  background-color: white;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  font-size: 18px;
  padding: 8px;
  border-radius: 6px;
  /* width: 160px; */
  margin-top: 10px;
  cursor: pointer;
}

.outlined-delete-btn {
  color: var(--red-color);
  background-color: white;
  border: 1px solid var(--red-color);
  font-size: 18px;
  border-radius: 8px;
  /* width: 40px; */
  height: 40px;
  cursor: pointer;
}

.table-outlined-delete-btn {
  color: var(--red-color);
  border: 1px solid var(--red-color);
  background-color: white;
  padding: 0px 10px;
}

.primary-btn {
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  font-size: 18px;
  padding: 8px;
  border-radius: 6px;
  width: 160px;
  cursor: pointer;
}

.dmn-form,
.table-parent {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  row-gap: 30px;
  column-gap: 20px;
  max-width: 1800px;
  overflow-x: auto;
  margin-bottom: 10px;
  scrollbar-width: thin;
}

.form-card {
  /* padding: 20px; */
  /* border: 1px solid var(--border-gray-500); */
  border-radius: 6px;
  display: grid;
  row-gap: 20px;
  margin: 0 !important;
}

.dmn-form-container {
  display: flex;
  justify-content: space-between;
  /* background-color: #CBDAF9; */
  /* background-color: #F5F7FE; */
  /* background-color: #e4ecfc; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  align-items: end;
  padding: 15px 20px;
  margin-bottom: 25px;
}

.add-field-btn-container {
  display: flex;
  justify-content: center;
}

.form-submit-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.form-divider {
  border-style: dashed;
}

/* modal & modal form style start */
.dmn-value-submission-modal-content {
  min-width: 700px;
  min-height: 260px;
  display: flex;
  flex-direction: row-reverse;
}

.dmn-value-submission-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.field-label {
  display: block;
  margin-bottom: 8px;
  font-size: 1.2rem;
  font-weight: 600;
}

.select-menu {
  font-size: 16px;
  /* border: 1px solid var(--border-gray-500); */
  background-color: white;
  border-radius: 6px;
  height: 44px !important;
  width: 200px !important;
}

.modal-submit-button {
  color: white;
  background: var(--primary-color);
  font-size: 18px;
  padding: 8px;
  border: none;
  border-radius: 6px;
  width: 160px;
  margin-left: auto;
  margin-top: 15px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--border-gray-500);
  /* padding: 16px; */
  /* margin: 0 -36px 0 -20px; */
}

/* modal & modal form style end */

/* Spacing Start */
.dmt-0 {
  margin-top: 0;
}

.dmb-4 {
  margin-bottom: 16px;
}

.dml-4 {
  margin-left: 16px;
}

.-dmx-2 {
  margin-left: -8px;
  margin-right: -8px;
}

.dml-2 {
  margin-left: 8px;
}

.dmr-2 {
  margin-right: 8px;
}

.dmy-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.dpx-2 {
  padding-left: 8px;
  padding-right: 8px;
}

/* Spacing End */

.ant-table-thead>tr>th {
  background-color: #CBDAF9 !important;
}

.table-card {
  margin-top: 20px;
}

.table-action-container {
  display: flex;
  gap: 10px;
}

.table-parent .ant-table-tbody .ant-table-cell {
  padding: 10px !important;
}

.table-action-container .ant-space-compact button:first-child {
  display: none;
}

.table-action-container .ant-space-compact button:last-child {
  border-radius: 6px !important;
}

.table-action-container .ant-space-compact .ant-btn-icon {
  transform: rotate(90deg);
}

.error-message {
  color: var(--red-color);
}

.modal-container {
  display: flex;
  gap: 12px;
}


.ant-table-thead>tr>th.output-column {
  background-color: #dbfceb !important;
}
.dmn-form-generator-parent .ant-dropdown-trigger.ant-table-filter-trigger{
  margin-right: 20px ! important;
}
.table-plus-icon{
  filter: drop-shadow(0px 0px 0px black);
}
.table-plus-icon:hover{
  filter:unset;
}
.add-row-button {
  color: var(--primary-color);
  background-color: white;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  font-size: 18px;
  padding: 4px;
  border-radius: 6px;
  cursor: pointer;
  width: 100px;
}
.add-row-button:hover{
  color: #021E4C;
}
.input-number-field{
  padding: 0px 11px;
  font-size: 16px;
  border: 1px solid var(--border-gray-500);
  background-color: white;
  border-radius: 6px;
  height: 42px;
  width: 100px;
}
.table-column .anticon-edit{
  cursor: pointer;
}